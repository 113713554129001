<template>
  <div class="commonNav">
    <div class="navBar">
      <div class="container navBarContent">
        <a href="/" target="_self" class="logo"><img src="../assets/images/all/logo.jpg"></a>
        <a class="mMenuBtn">
          <span class="l1"></span>
          <span class="l2"></span>
          <span class="l3"></span>
        </a>

        <ul class="topMainNav">
          <li>
            <router-link to="/news" class="en">NEWS</router-link>
            <router-link to="/news" class="cn">最新消息</router-link>
            <!--                        <a href="https://innovision.com.tw/news.php" target="_blank" class="en">NEWS</a>-->
            <!--                        <a href="https://innovision.com.tw/news.php" target="_blank" class="cn">最新消息</a>-->
          </li>
          <li>
            <router-link to="/about" class="en">ABOUT US</router-link>
            <router-link to="/about" class="cn">品牌故事</router-link>
            <!--                        <a href="https://innovision.com.tw/about.php" target="_blank" class="en">ABOUT US</a>-->
            <!--                        <a href="https://innovision.com.tw/about.php" target="_blank" class="cn">品牌故事</a>-->
          </li>
          <li>
            <router-link :to="{path:'/product' , query: { tab: 0 }}" class="en">PRODUCTS</router-link>
            <router-link :to="{path:'/product' , query: { tab: 0 }}" class="cn">所有產品</router-link>
            <!--                        <a href="https://innovision.com.tw/products.php" target="_blank" class="en">PRODUCTS</a>-->
            <!--                        <a href="https://innovision.com.tw/products.php" target="_blank" class="cn">所有產品</a>-->
          </li>
          <li>
            <router-link to="/store" class="en">AREA</router-link>
            <router-link to="/store" class="cn">銷售據點</router-link>
            <!--                        <a href="#" target="_self" class="en">AREA</a>-->
            <!--                        <a href="#" target="_self" class="cn">銷售據點</a>-->
          </li>
          <li>
            <router-link to="/contact" class="en">CONTACT</router-link>
            <router-link to="/contact" class="cn">聯絡我們</router-link>
            <!--                        <a href="https://innovision.com.tw/contact.php" target="_blank" class="en">CONTACT</a>-->
            <!--                        <a href="https://innovision.com.tw/contact.php" target="_blank" class="cn">聯絡我們</a>-->
          </li>
          <div class="clear"></div>
        </ul><!--topMainNav-->
        <div class="topIconNav appIconNav">
          <a href="https://www.facebook.com/innovision.tw/" target="_blank" class="ico-fb"></a>
          <a href="https://www.instagram.com/innovisiontw/" target="_blank" class="ico-ig"></a>
          <a href="https://weibo.com/u/5972911265" target="_blank" class="ico-wb"></a>
          <a href="https://lin.ee/NtVfcM0" target="_blank" class="ico-line"></a>
          <a href="https://www.innovisionshop.com/" target="_blank" class="ico-cart"></a>
        </div><!--topIconNav-->

      </div><!--end of navBarContent-->
    </div><!--end of navBar-->
    <div class="mMenuBtn_bg"></div>
    <div class="mainAd" v-if="show">

      <!--            <div id="carousel-example-generic" class="carousel slide" data-ride="carousel">-->
      <b-carousel controls
                  indicators
                  id="carousel-example-generic" class="carousel slide"
                  data-ride="carousel" v-model="slide">

        <b-carousel-slide v-for="(banner,i) in banners" :key="i">
          <template v-slot:img>
            <div class="item img1 active" @click="showDetail(banner)">
              <img :src="banner.pcURL" class="img-lg">
              <img :src="banner.mobileURL" class="img-xs">
              <!--                                <img src="../assets/images/all/ad-1-lg.jpg" class="img-lg">-->
              <!--                                <img src="../assets/images/all/ad-1-xs.jpg" class="img-xs">-->
            </div>
          </template>
        </b-carousel-slide>

        <!--                    <b-carousel-slide>-->
        <!--                        <template v-slot:img>-->
        <!--                            <div class="item img2">-->
        <!--                                <img src="../assets/images/all/ad-2-lg.jpg" class="img-lg">-->
        <!--                                <img src="../assets/images/all/ad-2-xs.jpg" class="img-xs">-->
        <!--                            </div>-->
        <!--                        </template>-->
        <!--                    </b-carousel-slide>-->


        <!--                    <b-carousel-slide>-->
        <!--                        <template v-slot:img>-->
        <!--                            <div class="item img3">-->
        <!--                                <img src="../assets/images/all/ad-3-lg.jpg" class="img-lg">-->
        <!--                                <img src="../assets/images/all/ad-3-xs.jpg" class="img-xs">-->
        <!--                            </div>-->
        <!--                        </template>-->
        <!--                    </b-carousel-slide>-->

        <!--                    &lt;!&ndash; Wrapper for slides &ndash;&gt;-->
        <!--                    <div class="carousel-inner" role="listbox">-->
        <!--                        <div class="item img1 active">-->
        <!--                            <img src="../assets/images/all/ad-1-lg.jpg" class="img-lg">-->
        <!--                            <img src="../assets/images/all/ad-1-xs.jpg" class="img-xs">-->
        <!--                        </div>-->
        <!--                        <div class="item img2">-->
        <!--                            <img src="../assets/images/all/ad-1-lg.jpg" class="img-lg">-->
        <!--                            <img src="../assets/images/all/ad-1-xs.jpg" class="img-xs">-->
        <!--                        </div>-->
        <!--                        <div class="item img3">-->
        <!--                            <img src="../assets/images/all/ad-1-lg.jpg" class="img-lg">-->
        <!--                            <img src="../assets/images/all/ad-1-xs.jpg" class="img-xs">-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!-- Controls -->
        <!--                    <a class="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">-->
        <!--                        <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>-->
        <!--                        <span class="sr-only">Previous</span>-->
        <!--                    </a>-->
        <!--                    <a class="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">-->
        <!--                        <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>-->
        <!--                        <span class="sr-only">Next</span>-->
        <!--                    </a>-->

      </b-carousel>
      <!--            </div>-->
    </div><!--end of mainAd-->
  </div>
</template>

<script>
import $ from 'jQuery';
import Notification from '../utils/NoticeManager';
import common from "@/mixins/common";

export default {
  name: "NavBar",
  mixins: [common],
  data() {
    return {
      show: false,
      slide: 0,
      banners: [],
    }
  },
  created() {
    console.log(`===created===`);

    Notification.addObserver('home', (data) => {
      console.log('=== Notification home ===')
      this.banners = data.banner;
    });

    $(function () {
      // mobile menu
      // eslint-disable-next-line no-undef
      $(".mMenuBtn").click(function () {
        // eslint-disable-next-line no-undef
        $('body').toggleClass("toggled");
      });

      $(".topMainNav a").click(function () {
        // eslint-disable-next-line no-undef
        if ($('body').hasClass('toggled')) {
          $('body').removeClass("toggled");
        } else {
          $('body').toggleClass("toggled");
        }
      });

      // menu高度動畫
      // eslint-disable-next-line no-undef
      $(window).load(function () {
        // eslint-disable-next-line no-undef
        $(window).bind('scroll resize', function () {
          // eslint-disable-next-line no-undef
          var $this = $(this);
          var $this_Top = $this.scrollTop();
          if ($this_Top < 90) {
            // eslint-disable-next-line no-undef
            $(".navBar").css({"height": "90px", "margin-top": "0px"});
          }
          if ($this_Top > 90) {
            // eslint-disable-next-line no-undef
            $(".navBar").css({"height": "80px", "margin-top": "-10px"});
          }
        }).scroll();
      });
    });


    // // AD手機滑動
    // // eslint-disable-next-line no-undef
    // $(function(){
    //     // eslint-disable-next-line no-undef
    //     $('#carousel-example-generic').hammer().on('swipeleft', function(){
    //         // eslint-disable-next-line no-undef
    //         $(this).carousel('next');
    //     });
    //
    //     // eslint-disable-next-line no-undef
    //     $('#carousel-example-generic').hammer().on('swiperight', function(){
    //         // eslint-disable-next-line no-undef
    //         $(this).carousel('prev');
    //     });
    // });
  },
  mounted() {
    this.show = true;
  },
  methods: {
    showDetail(product) {
      console.log(`showDetail banner == ${JSON.stringify(product)}`);
      this.showProductDetail(product);
    }
  }
}
</script>

