<template>
    <div class="wrapper">
        <NavBar v-if="!isIframe"></NavBar><!--commonNav-->
        <router-view/>
        <Footer v-if="!isIframe"></Footer><!--end of footer-->
    </div><!--end of wrapper-->
</template>

<script>

    import NavBar from "@/components/NavBar";
    import Footer from "@/components/Footer";

    export default {
        name: 'Index',
        data() {
            return {
                isIframe:false
            }
        },
        components: {
            NavBar,
            Footer
        },
        mounted() {
            console.log(`this.$route.query ${JSON.stringify(window.location.pathname) }`) // outputs 'yay'
            if(window.location.pathname.toLocaleLowerCase().includes('iframe'))
            {
                this.isIframe = true
            }
        },
    }
</script>
