<template>
    <div class="footer">
        <div class="container">
            <b-row>
                <b-col cols="12">
                    <ul class="footer-menu">
                        <li>
                          <router-link to="news">最新消息</router-link>
                        </li>&nbsp;｜&nbsp;
                        <li>
                          <router-link to="about">關於我們</router-link>
                        </li>&nbsp;｜&nbsp;
                        <li>
                          <router-link to="product">產品資訊</router-link>
                        </li>&nbsp;｜&nbsp;
                        <li>
                          <router-link to="store">銷售據點</router-link>
                        </li>&nbsp;｜&nbsp;
                        <li>
                          <router-link to="contact">聯絡我們</router-link>
                        </li>
                    </ul>
                </b-col>
            </b-row><!--row-->
            <b-row>
                <b-col cols="12" sm="4" class="footer-logo">
                    <img src="../assets/images/all/footer-logo.png">
                </b-col>
                <b-col cols="12" sm="4" class="footer-remind">
                    <div class="ftIconNav-m">
                        <a href="https://www.facebook.com/innovision.tw/" target="_blank" class="ico-fb"></a>
                        <a href="https://www.instagram.com/innovisiontw/" target="_blank" class="ico-ig"></a>
                        <a href="https://www.instagram.com/innovisiontw/" target="_blank" class="ico-wb"></a>
                        <a href="https://lin.ee/NtVfcM0" target="_blank" class="ico-line"></a>
                       <a href="https://www.innovisionshop.com/" target="_blank" class="ico-cart"></a>
                    </div>
<!--                    <div class="appIconNav-m">-->
<!--&lt;!&ndash;                      <a href="https://play.google.com/store/apps/details?id=io.rcdesign.innovision" target="_blank" class="app-android"></a>&ndash;&gt;-->
<!--&lt;!&ndash;                      <a href="https://apps.apple.com/tw/app/%E5%84%AA%E8%A6%96%E9%81%94/id1247975100" target="_blank" class="app-ios"></a>&ndash;&gt;-->
<!--                      <a href="https://www.innovisionshop.com/" target="_blank" class="app-web"></a>-->
<!--                    </div>-->
                    <ul>
                        <li class="footer-remind-title">優視達提醒</li>
                        <li>＊配戴一般隱形眼鏡須經眼科醫師驗光配鏡取得處方箋，或經驗 光人員驗光配鏡取得配鏡單，並定期接受眼科醫師追蹤檢查。</li>
                        <li>＊本器材不得逾中文仿單建議之最長配戴時數、不得重覆配戴，於就寢前務必取下，以免感染或潰瘍。</li>
                        <li>＊如有不適，應立即就醫。</li>
                    </ul>
                </b-col>
                <b-col cols="12" sm="4" class="footer-contact">
                    <ul>
                        <li class="footer-contact-title">聯絡資訊</li>
                        <li>服務專線: (07)556-1219</li>
                        <li>客服信箱: <a href="#" target="_self" class="mail">service@innovision.tw</a></li>
                        <li class="footer-contact-title2">
                            <span class="spanTitle">追蹤我們</span>
                            <div class="ftIconNav">
                                <a href="https://www.facebook.com/innovision.tw/" target="_blank" class="ico-fb"></a>
                                <a href="https://www.instagram.com/innovisiontw/" target="_blank" class="ico-ig"></a>
                                <a href="https://weibo.com/u/5972911265" target="_blank" class="ico-wb"></a>
                                <a href="https://lin.ee/NtVfcM0" target="_blank" class="ico-line"></a>
                              <a href="https://www.innovisionshop.com/" target="_blank" class="ico-cart"></a>
                            </div>
                        </li>
<!--                        <li class="footer-contact-title3">-->
<!--                          <div class="appIconNav">-->
<!--&lt;!&ndash;                            <a href="https://play.google.com/store/apps/details?id=io.rcdesign.innovision" target="_blank" class="app-android"></a>&ndash;&gt;-->
<!--&lt;!&ndash;                            <a href="https://apps.apple.com/tw/app/%E5%84%AA%E8%A6%96%E9%81%94/id1247975100" target="_blank" class="app-ios"></a>&ndash;&gt;-->
<!--                            <a href="https://www.innovisionshop.com/" target="_blank" class="app-web"></a>-->
<!--                          </div>-->
<!--                        </li>-->
                    </ul>
                </b-col>
            </b-row><!--row-->
            <b-row>
                <b-col cols="12" class="footer_copyright">
                    <p>Copyright © 2020 INNOVISION 優視達光學 All rights reserved.</p>
                </b-col>
            </b-row>
        </div><!--container-->
    </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

