<template>
  <div id="app">
    <Index/>

    <div id="contact_PopWd" ref="contact_PopWd" class="PopupWindow">
      <div class="PopupFrame">
        <div class="pop_content">
          <p class="p1">{{dialog.title}}</p>
          <p class="p2">{{dialog.message}}</p>
          <a href="#" target="_self" class="BtnOk" @click.prevent="dialog.confirm">確定</a>
        </div><!--pop_content-->
      </div><!--frame-->
    </div><!--PopupWindow-->


    <div id="link_PopWd" ref="contact_PopWd" class="PopupWindow">
      <div class="PopupFrame">
        <div class="pop_content">
          <a href="#" class="BtnOk" style="border-radius: 15px; margin-top: 0" @click.prevent="dialog.confirm">X</a>
          <br/> <br/>
          <b-row>
            <b-col cols="6" md="4" class="listBox" v-for="(link, i) in dialog.list" :key="i">
              <a :href="link.url" target="_blank" class="mt-1 IntroAreaBtn" @click.prevent="closeDialog(link.url)"
              >{{link.title}}</a>
            </b-col><!--listBox-->
          </b-row><!--row-->
        </div><!--pop_content-->
      </div><!--frame-->
    </div><!--PopupWindow-->

    <div id="blockOverlay"></div>
  </div>
</template>

<script>
import Index from './components/Index.vue'
import NoticeManager from "@/utils/NoticeManager";

export default {
  name: 'App',
  components: {
    Index
  },
  data(){
    return {
      dialog:{
        title:'',
        message:'',
        list:[],
        confirm: ()=>{},
      }
    };
  },
  created() {
    this.initPopup();

    NoticeManager.addObserver('saveContact', (dialog)=>{
      this.dialog.title = dialog.title;
      this.dialog.message = dialog.message;
      this.dialog.confirm = ()=>{
        // eslint-disable-next-line no-undef
        $('#contact_PopWd').toggleClass("PopWdshow");
        // eslint-disable-next-line no-undef
        $('#blockOverlay').toggleClass("blockshow");
      };

      // eslint-disable-next-line no-undef
      $('#contact_PopWd').toggleClass("PopWdshow");
      // eslint-disable-next-line no-undef
      $('#blockOverlay').toggleClass("blockshow");
    });

    NoticeManager.addObserver('showLink', (dialog)=>{
      this.dialog.list = dialog.list;
      this.dialog.confirm = ()=>{
        // eslint-disable-next-line no-undef
        $('#link_PopWd').toggleClass("PopWdshow");
        // eslint-disable-next-line no-undef
        $('#blockOverlay').toggleClass("blockshow");
      };

      // eslint-disable-next-line no-undef
      $('#link_PopWd').toggleClass("PopWdshow");
      // eslint-disable-next-line no-undef
      $('#blockOverlay').toggleClass("blockshow");
    });
  },
  methods:{
    closeDialog(url){
      window.open(url, '_blank');
      // eslint-disable-next-line no-undef
      $('#link_PopWd').toggleClass("PopWdshow");
      // eslint-disable-next-line no-undef
      $('#blockOverlay').toggleClass("blockshow");
    },
    initPopup(){
      //contact_PopWd
      // eslint-disable-next-line no-undef
      $(function () {
        // // eslint-disable-next-line no-undef
        // $(".contact_PopWd").click(function () {
        //   // eslint-disable-next-line no-undef
        //   $('#contact_PopWd').toggleClass("PopWdshow");
        //   // eslint-disable-next-line no-undef
        //   $('#blockOverlay').toggleClass("blockshow");
        // });
        //for all popwindow
        // // eslint-disable-next-line no-undef
        // $(".btnClose").click(function () {
        //   // eslint-disable-next-line no-undef
        //   $('.PopupWindow').removeClass("PopWdshow");
        //   // eslint-disable-next-line no-undef
        //   $('#blockOverlay').removeClass("blockshow");
        // });
        // eslint-disable-next-line no-undef
        $("#blockOverlay").click(function () {
          // eslint-disable-next-line no-undef
          $('.PopupWindow').removeClass("PopWdshow");
          // eslint-disable-next-line no-undef
          $('#blockOverlay').removeClass("blockshow");
        });
        // eslint-disable-next-line no-undef
        $(".BtnOk").click(function () {
          // eslint-disable-next-line no-undef
          $('.PopupWindow').removeClass("PopWdshow");
          // eslint-disable-next-line no-undef
          $('#blockOverlay').removeClass("blockshow");
        });
      });
    }
  }
}
</script>

<style lang="css">
  @import "/assets/bootstrap/css/bootstrap.min.css";

</style>
