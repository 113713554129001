import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const Home = () => import(/* webpackChunkName: 'DashboardView' */ '../pages/home/Home');
const News = () => import(/* webpackChunkName: 'DashboardView' */ '../pages/news/News');
const NewsDetail = () => import(/* webpackChunkName: 'DashboardView' */ '../pages/news/NewsDetail');
const Store = () => import(/* webpackChunkName: 'DashboardView' */ '../pages/store/Store');
const Contact = () => import(/* webpackChunkName: 'DashboardView' */ '../components/Contact');
const Product = () => import(/* webpackChunkName: 'DashboardView' */ '../pages/product/index');
const ProductList = () => import(/* webpackChunkName: 'DashboardView' */ '../pages/product/productList');
const ProductDetail = () => import(/* webpackChunkName: 'DashboardView' */ '../pages/product/productDetail');
const ProductGroup = () => import(/* webpackChunkName: 'DashboardView' */ '../pages/product/productGroup');
const About = () => import(/* webpackChunkName: 'DashboardView' */ '../pages/about/index');
const Sale = () => import(/* webpackChunkName: 'DashboardView' */ '../pages/sale/index');

const router = new Router({
    mode: 'history',
    routes: [{
        path: '/',
        name: 'index',
        component: Home,
    },
    {
        path: '/store-iframe',
        name: 'Store-iframe',
        component: Store,
    },
        {
            path: '/store',
            name: 'Store',
            component: Store,
            beforeEnter: (to, from, next) => {
                next({ query: to.query });
            },
        },
        {
            path: '/product',
            name: 'Product',
            component: Product,
            beforeEnter: (to, from, next) => {
                next({ query: to.query });
            },
        },
        {
            path: '/productList',
            name: 'ProductList',
            component: ProductList,
            beforeEnter: (to, from, next) => {
                next({ query: to.query });
            },
        },{
            path: '/productGroup',
            name: 'ProductGroup',
            component: ProductGroup,
            beforeEnter: (to, from, next) => {
                next({ query: to.query });
            },
        }, {
            path: '/productInfo',
            name: 'ProductDetail',
            component: ProductDetail,
            beforeEnter: (to, from, next) => {
                next({ query: to.query });
            },
        },{
            path: '/news',
            name: 'News',
            component: News,
            beforeEnter: (to, from, next) => {
                next({ query: to.query });
            },
        },{
            path: '/newsDetail',
            name: 'NewsDetail',
            component: NewsDetail,
            beforeEnter: (to, from, next) => {
                next({ query: to.query });
            },
        },{
            path: '/contact',
            name: 'Contact',
            component: Contact,
            beforeEnter: (to, from, next) => {
                next({ query: to.query });
            },
        },{
            path: '/about',
            name: 'About',
            component: About,
            beforeEnter: (to, from, next) => {
                next({ query: to.query });
            },
        },{
            path: '/sale',
            name: 'Sale',
            component: Sale,
            beforeEnter: (to, from, next) => {
                next({ query: to.query });
            },
        }]
});

function getRoutesList(routes, pre) {
    return routes.reduce((array, route) => {
        const path = `${pre}${route.path}`;

        if (route.path !== '*') {
            array.push(path);
        }

        if (route.children) {
            array.push(...getRoutesList(route.children, `${path}/`));
        }

        return array;
    }, []);
}

function getRoutesXML() {
    const list = getRoutesList(router.options.routes, 'https://www.innovision.com.tw/#')
        .map(route => `<url><loc>${route}</loc></url>`)
        .join('\r\n');
    console.log(`route list ${JSON.stringify(list)}`);
    return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
    ${list}
  </urlset>`;
}

//產生 Sitemap 字串
const sitemap = getRoutesXML();
console.log(sitemap);

export default router;
