

<script>
import MobileDetect from 'mobile-detect';

const md = new MobileDetect(window.navigator.userAgent);
export default {
name: "common",
  computed: {
    isMobile: () => md.phone(),
  },
  methods:{
    toTop(){
      window.scrollTo(0, 0);
    },
    showToTop() {
      if (this.isMobile) {
        this.toTop();
      }
    },
    showProductDetail(product){
      console.log(JSON.stringify(product));
      if (product.enableType === 0){
        const query = { tab: -1, productId: product.productId };
        localStorage.setItem('cache_query', JSON.stringify(query));
        this.$router.push({ path:'/productInfo' , query: { id: product.productId, enableType: product.enableType,
            t: new Date().getTime()}});
      } else if (product.enableType === 1){
        const query = { tab: 3, productId: product.productId, group: product.productGroup, groupName: '', disposable: 0, enableType: product.enableType};
        localStorage.setItem('cache_query', JSON.stringify(query));
        this.$router.push({ path:'/productGroup' , query: { id: product.productId, group:product.productGroup,
            enableType: product.enableType, t: new Date().getTime() }});
      } else if (product.enableType === 2){
        if (product.saleURL){
          window.open(product.saleURL, '_blank');
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
