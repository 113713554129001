import Vue from 'vue'
import App from './App.vue';
import router from './router/index';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/css/all.css';
import './assets/css/quill.core.css';

import MetaInfo from 'vue-meta-info'

Vue.use(MetaInfo);

import { BootstrapVue } from 'bootstrap-vue';
// Install BootstrapVue
Vue.use(BootstrapVue);

import jQuery from 'jQuery';
window.$ = window.jQuery = jQuery;


Vue.config.productionTip = false;
new Vue({
  router,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  },
}).$mount('#app')
